import { $busEmit } from '@/plugins'
import orders from '@/api/modules/orders'
import ordersCols from '@/assets/json/orders-cols'

export default {
  namespaced: true,
  state: () => ({
    rows: [],
    knp: [],
    kbe: [],
    types: [],
    selectedRows: [],
    item: null,
    size: 50,
    page: 1,
    pageCount: 1,
    tableCols: localStorage.getItem('choco_cols') ? JSON.parse(localStorage.getItem('choco_cols')) : [...ordersCols],
    tableGrouped: '',
    tableSort: localStorage.getItem('choco_sort')
      ? JSON.parse(localStorage.getItem('choco_sort'))
      : {
          direction: 'desc',
          key: 'created_at'
        },
    filters: localStorage.getItem('choco_filters') ? JSON.parse(localStorage.getItem('choco_filters')) : {},
    optionsPayType: [
      {
        text: 'Напрямую поставщику',
        value: 'counterparty'
      },
      // {
      //   text: 'Регулярные платежи',
      //   value: 'regular_payments'
      // },
      {
        text: 'Возьму себе в подотчет',
        value: 'subreport_open'
      },
      {
        text: 'Возьму себе в подотчет (без закрывашек)',
        value: 'subreport_closed'
      },
      {
        text: 'Аванс для сотрудника',
        value: 'prepayment'
      },
      {
        text: 'Оплата по договору ГПХ',
        value: 'gpc_agreement'
      },
      {
        text: 'Займы холдинга',
        value: 'holding_loans'
      },
      {
        text: 'Прочее',
        value: 'memo'
      }
    ],
    currencyOptions: [
      {
        text: 'Евро',
        value: 'eur'
      },
      {
        text: 'Доллар',
        value: 'usd'
      },
      {
        text: 'Рубли',
        value: 'rub'
      },
      {
        text: 'Тенге',
        value: 'kzt'
      }
    ]
  }),
  mutations: {
    SET_TABLE_GROUPED_BY(state, payload) {
      state.tableGrouped = payload
    },
    RESET_COLS(state) {
      localStorage.removeItem('choco_cols')
      state.tableCols = [...ordersCols]
    },
    SET_SIZE(state, payload) {
      state.size = payload
    },
    SET_PAGE(state, payload) {
      state.page = payload
    },
    SET_KNP(state, payload) {
      state.knp = payload
    },
    SET_KBE(state, payload) {
      state.kbe = payload
    },
    SET_PAGE_COUNT(state, payload) {
      state.pageCount = payload
    },
    SET_COLS(state, payload) {
      localStorage.setItem('choco_cols', JSON.stringify(payload))
      state.tableCols = payload
    },
    SET_SORT(state, payload) {
      const res = {
        direction: state.tableSort.direction === 'asc' ? 'desc' : 'asc',
        key: payload
      }
      localStorage.setItem('choco_sort', JSON.stringify(res))
      state.tableSort = res
    },
    SET_FILTERS(state, { key, value }) {
      if (value) state.filters[key] = value
      else delete state.filters[key]
      localStorage.setItem('choco_filters', JSON.stringify(state.filters))
    },
    CLEAR_FILTERS(state) {
      state.filters = {}
      localStorage.setItem('choco_filters', JSON.stringify(state.filters))
    },
    SET_ROWS(state, payload) {
      state.rows = payload
    },
    SET_MORE_ROWS(state, payload) {
      state.rows = [...state.rows, ...payload]
    },
    SET_ITEM(state, payload) {
      state.item = payload
    },
    SET_OPTIONS(state, payload) {
      state.page = payload?.current_page
      state.size = payload?.per_page
      state.pageCount = payload?.last_page
    },
    SET_TYPES(state, payload) {
      state.types = payload
    },
    ADD_ROW(state, payload) {
      let index = state.rows.findIndex((item) => item.id == payload.id)
      if (index === -1) {
        state.rows.unshift(payload)
      }
    },
    UPDATE_ROW(state, payload) {
      const index = state.rows.findIndex((i) => i.id == payload.id)
      if (index != -1) {
        state.rows[index] = payload
      }
    },
    DELETE_ROW(state, payload) {
      state.rows = state.rows.filter((i) => i.id != payload)
    },
    UPDATE_ROWS(state, payload) {
      for (let i = 0; i < payload.length; i++) {
        let index = state.rows.findIndex((item) => item.id == payload[i].id)
        if (index != -1) {
          state.rows[index] = payload[i]
        }
      }
    },
    SELECT_ROW(state, payload) {
      if (state.selectedRows.includes(payload)) {
        state.selectedRows = state.selectedRows.filter((i) => i != payload)
      } else {
        state.selectedRows.push(payload)
      }
    },
    CLEAR_SELECTED_ROWS(state) {
      state.selectedRows = []
    }
  },
  actions: {
    async GET_MORE_ROWS({ commit, state }) {
      try {
        const { data } = await orders.getAll(state.filters, state.tableSort, state.size, state.page + 1)
        commit('SET_OPTIONS', data.meta)
        commit('SET_PAGE', state.page)
        commit('SET_MORE_ROWS', data.data)
      } catch (e) {
        console.log(e)
        $busEmit('setToast', {
          type: 'red',
          icon: 'alert-triangle',
          title: e?.response?.status + ' ' + e?.response?.statusText || 'Произошла ошибка',
          message: 'Не удалось получить список заявок, обратитесь к Администратору или перезагрузите страницу'
        })
      }
    },
    async GET_KNP({ commit }) {
      try {
        const { data } = await orders.getKnp()
        commit('SET_KNP', data.data)
      } catch (e) {
        console.log(e)
        $busEmit('setToast', {
          type: 'red',
          icon: 'alert-triangle',
          title: e?.response?.status + ' ' + e?.response?.statusText || 'Произошла ошибка',
          message: 'Не удалось получить список КНП, обратитесь к Администратору или перезагрузите страницу'
        })
      }
    },
    async GET_KBE({ commit }) {
      try {
        const { data } = await orders.getKbe()
        commit('SET_KBE', data.data)
      } catch (e) {
        console.log(e)
        $busEmit('setToast', {
          type: 'red',
          icon: 'alert-triangle',
          title: e?.response?.status + ' ' + e?.response?.statusText || 'Произошла ошибка',
          message: 'Не удалось получить список КБЕ, обратитесь к Администратору или перезагрузите страницу'
        })
      }
    },
    async GET_TYPES({ commit }) {
      try {
        const { data } = await orders.getTypes()
        commit('SET_TYPES', data.data)
      } catch (e) {
        console.log(e)
        $busEmit('setToast', {
          type: 'red',
          icon: 'alert-triangle',
          title: e?.response?.status + ' ' + e?.response?.statusText || 'Произошла ошибка',
          message: 'Не удалось получить список типов оплаты, обратитесь к Администратору или перезагрузите страницу'
        })
      }
    },
    async GET_ROWS({ commit, state }) {
      try {
        const grouped = localStorage.getItem('choco_grouped')
        const size = grouped ? 200 : 50
        const page = grouped ? 4 : 1
        const { data } = await orders.getAll(state.filters, state.tableSort, size, 1)
        commit('SET_OPTIONS', data.meta)
        commit('SET_ROWS', data.data)
        commit('SET_PAGE', page)
      } catch (e) {
        console.log(e)
        $busEmit('setToast', {
          type: 'red',
          icon: 'alert-triangle',
          title: e?.response?.status + ' ' + e?.response?.statusText || 'Произошла ошибка',
          message: 'Не удалось получить список заявок, обратитесь к Администратору или перезагрузите страницу'
        })
      }
    },
    async GET_ITEM({ commit }, id) {
      try {
        const { data } = await orders.get(id)
        commit('SET_ITEM', data.data)
      } catch (e) {
        console.log(e)
      }
    },
    async ADD_NEW({ dispatch }, payload) {
      try {
        await orders.create(payload)
        dispatch('GET_ROWS')
      } catch (e) {
        console.log(e)
      }
    }
  },
  getters: {
    tableCols: (s) => s.tableCols,
    tableGrouped: (s) => s.tableGrouped,
    tableSort: (s) => s.tableSort,
    filters: (s) => s.filters,
    rows: (s) => s.rows,
    item: (s) => s.item,
    getSize: (s) => s.size,
    getPage: (s) => s.page,
    getPageCount: (s) => s.pageCount,
    getTypes: (s) => s.types,
    getKnp: (s) => s.knp,
    getKbe: (s) => s.kbe,
    optionsPayType: (s) => s.optionsPayType,
    currencyOptions: (s) => s.currencyOptions,
    selectedRows: (s) => s.selectedRows
  }
}
