import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

// NPM
import CKEditor from '@ckeditor/ckeditor5-vue'
import vClickOutside from 'click-outside-vue3'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'

// Style
// import 'ktim-scss'
import '@/assets/scss/themes.css'
import '@/assets/scss/main.scss'
import '@/assets/scss/modules/btns.scss'
import '@/assets/scss/modules/spaces.scss'
import '@/assets/scss/modules/flex-grid.scss'
import '@/assets/scss/modules/forms.scss'
import '@/assets/scss/modules/colors-mixin.scss'

const app = createApp(App)
app.provide('profileLink', 'https://intra.chocofamily.kz/app/user/')

app.use(store)
app.use(router)
app.use(vClickOutside)
app.use(CKEditor)
app.use(VueTippy, {
  directive: 'tippy',
  component: 'tippy',
  componentSingleton: 'tippy-singleton',
  defaultProps: {
    placement: 'top',
    allowHTML: true
  }
})

app.mount('#app')
