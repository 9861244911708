import axios from '../axios'

class UsersApi {
  getAll(searchText = '') {
    const ser = searchText ? searchText : 'aбa'
    return axios.get('/api/search?fullname=' + ser)
  }
  getResponsible(searchText = '') {
    const ser = searchText ? searchText : 'aaa'
    return axios.get('/api/search?fullname=' + ser)
  }
}

export default new UsersApi()
