export default [
  {
    path: 'orders',
    meta: {
      title: 'Заявки'
    },
    component: () => import(/* webpackChunkName: "page-orders" */ '@/views/orders/index.vue'),
    children: [
      {
        path: 'add',
        meta: {
          title: 'Новая заявка'
        },
        component: () => import(/* webpackChunkName: "page-orders-new" */ '@/views/orders/order-add.vue')
      },
      {
        path: 'view/:id',
        meta: {
          title: 'Просмотр заявки'
        },
        component: () => import(/* webpackChunkName: "page-orders-view" */ '@/views/orders/order-view.vue')
      },
      {
        path: 'edit/:id',
        meta: {
          title: 'Редактирование заявки'
        },
        component: () => import(/* webpackChunkName: "page-orders-edit" */ '@/views/orders/order-edit.vue')
      }
    ]
  }
]
