export default [
  {
    path: 'settings',
    component: () => import(/* webpackChunkName: "page-settings" */ '@/views/settings/index.vue'),
    children: [
      {
        path: '',
        meta: {
          title: 'Управление - уведомления'
        },
        component: () =>
          import(/* webpackChunkName: "page-settings-notifications" */ '@/views/settings/notifications.vue')
      },
      {
        path: 'companies',
        meta: {
          title: 'Управление - компании'
        },
        component: () => import(/* webpackChunkName: "page-settings-statuses" */ '@/views/settings/companies.vue')
      }
    ]
  }
]
