// import { cutFullName, formatRuDateTime } from '@/plugins/helpers'

export default [
  {
    name: 'Выбор',
    key: 'can_approve',
    filter: false,
    sort: false,
    clickDisabled: true,
    show: true,
    disabled: false,
    flex: ''
  },
  {
    name: '№',
    key: 'id',
    filter: false,
    sort: false,
    show: true,
    disabled: false,
    width: '70px',
    flex: ''
  },
  {
    name: 'Проект',
    key: 'company',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: '150px',
    flex: '1.5'
  },
  {
    name: 'Инициатор',
    key: 'author',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto',
    flex: '1.5'
  },
  {
    name: 'Тип оплаты',
    key: 'type',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto',
    flex: '2'
  },
  {
    name: 'Контрагент',
    key: 'counterparty',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto',
    flex: '1.5'
  },
  {
    name: 'За что платим',
    key: 'pay_for',
    filter: false,
    sort: false,
    show: true,
    disabled: true
  },
  {
    name: 'Сумма',
    key: 'cost',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: '130px',
    flex: '1.5',
    side: 'right'
  },
  {
    name: 'Валюта',
    key: 'currency',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: '60px',
    flex: ''
  },
  {
    name: 'Дата план.оплаты',
    key: 'pay_date_planned',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: '100px',
    flex: ''
  },
  {
    name: 'Дата факт.оплаты',
    key: 'pay_date',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    width: 'auto',
    flex: '1'
  },
  {
    name: 'Статус',
    key: 'status',
    filter: true,
    sort: false,
    show: true,
    disabled: false,
    flex: ''
  },
  {
    name: 'Дата создания',
    key: 'created_at',
    filter: false,
    sort: true,
    show: true,
    disabled: false,
    width: '170px',
    canHide: true,
    flex: ''
  },
  {
    name: 'Дата изменения',
    key: 'updated',
    filter: false,
    sort: false,
    show: false,
    disabled: false,
    width: '170px',
    flex: ''
  }
]
