import { createStore } from 'vuex'
// import { $busEmit } from '@/plugins'

// Api modules
import main from '@/api/modules/main'
import users from '@/api/modules/users'
import projects from '@/api/modules/projects'
import counterparties from '@/api/modules/counterparties'

// Vuex modules
import helpers from './helpers'
import orders from './orders'
import events from './events'
import notifications from './notifications'

const defaultState = () => {
  return {
    timerID: -1,
    timer: 7200000,
    theme: 'light',
    prefix: 'choco',
    token: JSON.parse(localStorage.getItem('choco_token')) || null,
    auth: JSON.parse(localStorage.getItem('choco_auth')) || null,
    profile: JSON.parse(localStorage.getItem('choco_profile')) || null,
    isAccountant: false,
    isFinancier: false,
    isHeadFinancier: false,
    isCompanyHead: false,
    user: null,
    users: [],
    responsibles: [],
    projects: [],
    counterparties: [],
    banks: []
  }
}

export default createStore({
  state: defaultState(),

  mutations: {
    CLEAR_STATE(state) {
      Object.assign(state, defaultState())
    },
    SET_TIMER(state, payload) {
      state.timer = payload
    },
    SET_TIMERID(state, payload) {
      state.timerID = payload
    },
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_AUTH(state, payload) {
      state.auth = payload
    },
    SET_PROFILE(state, payload) {
      state.profile = payload
      state.isAccountant = payload.financier_role === 'Accountant'
      state.isFinancier = payload.financier_role === 'Financier'
      state.isHeadFinancier = payload.financier_role === 'Senior Financier'
      state.isCompanyHead = payload.financier_role === 'Company Head'
    },
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_USERS(state, payload) {
      const users = []
      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        users.push({
          id: item.id,
          text: item.fullname,
          position: item.specialization || 'Нет должности',
          value: item.id,
          avatar: item.avatar
        })
      }
      state.users = users
    },
    SET_RESPONSIBLES(state, payload) {
      const users = []
      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        users.push({
          id: item.id,
          text: item.fullname,
          position: item.specialization || 'Нет должности',
          value: item.id,
          avatar: item.avatar
        })
      }
      state.responsibles = users
    },
    SET_PROJECTS(state, payload) {
      state.projects = payload.map((item) => {
        return {
          id: item.id,
          text: item.project,
          value: item.id,
          item: { ...item }
        }
      })
    },
    SET_BANKS(state, payload) {
      state.banks = payload.map((el) => {
        return {
          id: el.id,
          text: el.name,
          value: el.id,
          bik: el.bik
        }
      })
    },
    SET_COUNTERPARTIES(state, payload) {
      const counterparties = []
      for (let i = 0; i < payload.length; i++) {
        const item = payload[i]
        counterparties.push({
          contracts: item.contracts,
          id: item.id,
          text: item.bin + ' - ' + item.company_name,
          value: item.bin,
          name: item.company_name,
          kbe: item.kbe,
          knp: item.knp,
          accountants: item.accountants,
          bin: item.bin
        })
      }
      state.counterparties = counterparties
    }
  },

  actions: {
    async GET_ME(_, access_token) {
      return await main.getMe(access_token)
    },
    GET_PROFILE({ getters, commit }) {
      return main.getProfile().then(({ data }) => {
        localStorage.setItem(`${getters.prefix}_profile`, JSON.stringify(data.data))
        commit('SET_PROFILE', data.data)
      })
    },
    GET_RESPONSIBLES({ commit }, payload) {
      if (payload) {
        return users
          .getResponsible(payload)
          .then(({ data }) => {
            commit('SET_RESPONSIBLES', data.data)
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        return new Promise((resolve) => resolve())
      }
    },
    GET_USER({ commit }, id) {
      return (
        id &&
        main.getOtherProfile(id).then(({ data }) => {
          commit('SET_USER', data.data)
        })
      )
    },
    async LOGOUT() {
      return await main.logout()
    },
    GET_USERS({ commit }, payload) {
      if (payload) {
        return users
          .getAll(payload)
          .then(({ data }) => {
            commit('SET_USERS', data.data)
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        return new Promise((resolve) => resolve())
      }
    },
    GET_COUNTERPARTIES_SEARCH({ commit }, payload) {
      if (payload) {
        return counterparties
          .searchCounterparties(payload)
          .then(({ data }) => {
            commit('SET_COUNTERPARTIES', data.data)
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        return new Promise((resolve) => resolve())
      }
    },
    GET_BANKS({ commit }) {
      return counterparties
        .getBanks()
        .then(({ data }) => {
          commit('SET_BANKS', data.data)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    GET_PROJECTS({ commit }, payload) {
      return projects
        .getAll(payload)
        .then(({ data }) => {
          commit('SET_PROJECTS', data.data)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    GET_COUNTERPARTIES({ commit }, payload) {
      return counterparties
        .getAll(payload)
        .then(({ data }) => {
          commit('SET_COUNTERPARTIES', data.data)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  },

  getters: {
    timerID: (s) => s.timerID,
    timer: (s) => s.timer,
    prefix: (s) => s.prefix,
    token: (s) => s.token,
    auth: (s) => s.auth,
    profile: (s) => s.profile,
    user: (s) => s.user,
    users: (s) => s.users,
    banks: (s) => s.banks,
    responsibles: (s) => s.responsibles,
    projects: (s) => s.projects,
    counterparties: (s) => s.counterparties,
    isAccountant: (s) => s.isAccountant,
    isFinancier: (s) => s.isFinancier,
    isHeadFinancier: (s) => s.isHeadFinancier,
    isCompanyHead: (s) => s.isCompanyHead
  },

  modules: {
    helpers,
    orders,
    events,
    notifications
  }
})
