import axios from '../axios'

class CounterpartiesApi {
  getAll(status) {
    let url = '/api/financier-application/counterparty'
    status ? (url += `?type=${status}`) : ''
    return axios.get(url)
  }
  searchCounterparties({ searchText, type }) {
    let url = `/api/financier-application/counterparty/search?s=${searchText}`
    type ? (url += `&type=${type}`) : ''
    return axios.get(url)
  }
  get(id) {
    return axios.get(`/api/financier-application/counterparty/${id}`)
  }
  getBanks() {
    return axios.get('/api/financier-application/counterparty/banks')
  }
}

export default new CounterpartiesApi()
