export default [
  {
    path: 'user',
    component: () => import(/* webpackChunkName: "page-user" */ '@/views/user/index.vue'),
    children: [
      {
        path: ':id',
        meta: {
          title: 'Профиль'
        },
        component: () => import(/* webpackChunkName: "page-user-profile" */ '@/views/user/profile.vue')
      },
      {
        path: ':id/settings',
        meta: {
          title: 'Настройки'
        },
        component: () => import(/* webpackChunkName: "page-orders-view" */ '@/views/user/settings.vue')
      }
    ]
  }
]
